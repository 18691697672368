



























import Vue from 'vue'
import { getLoginInfo } from '@/plugins/user'

export default Vue.extend({
  middleware: ['auth'],
  data() {
    return {
      locale: 'ja',
      authFlg: false,
      hznHolder: '',
      alertId: '',
      quote: '',
      srchCondition: this.$$getInitialValue('srchCondition'),
      favoriteKijiInfo: { uuid: '' },
    }
  },
  created() {
    this.$nuxt.$on('updateLocale', this.setLocale)
  },
  mounted() {
    // スクロール位置を0にする
    window.scrollTo(0, 0)

    // パスワード変更フラグが「1: 初回・再発行」or「2: 年度切替」の場合はパスワード変更画面へ
    if (
      getLoginInfo('LK_PASSCHG_FLAG') === '1' ||
      getLoginInfo('LK_PASSCHG_FLAG') === '2'
    ) {
      if (
        (getLoginInfo('L_LOGIN_CNO') === '6' ||
          getLoginInfo('L_LOGIN_CNO') === '7') &&
        getLoginInfo('RL_LOGIN_CNO') === '1'
      ) {
        this.$router.push('/devicepasswd/')
      } else {
        this.$router.push('/passwd/')
      }
      return
    }

    // 認証失敗の場合はページを表示しない
    if (getLoginInfo('L_LOGINID') !== false) {
      this.authFlg = true
    }

    // グローバルなオブジェクトにハンドラ追加
    this.$nuxt.$on('setHznHolder', this.setHznHolder)
    this.$nuxt.$on('setAlertId', this.setAlertId)
    this.$nuxt.$on('setQuote', this.setQuote)
    this.$nuxt.$on('setSrchCondition', this.setSrchCondition)
    this.$nuxt.$on('setFavoriteKijiInfo', this.setFavoriteKijiInfo)
  },
  methods: {
    setLocale(locale: string): void {
      this.locale = locale || 'ja'
    },

    /**
     * mainコンポーネントを再描画する
     * @params {number} flg
     * @return {void}
     */
    refreshMainVue(flg: number = 0): void {
      if (flg) {
        // データをセット
        this.$$getMainVueInstance().$$setData()
      }

      // mainコンポーネントを再描画する
      this.$$getMainVueInstance().$forceUpdate()
    },

    /**
     * 保存フォルダ名をセット
     * @params {string} hznHolder
     * @return {void}
     */
    setHznHolder(hznHolder: string): void {
      console.log('hznHolder', hznHolder)
      this.hznHolder = hznHolder
    },

    /**
     * alertIdをクリア
     * @return {void}
     */
    clearAlertId(): void {
      this.alertId = ''
    },

    /**
     * アラート表示用のIDをセット
     * @params {string} alertId
     * @return {void}
     */
    setAlertId(alertId: string): void {
      console.log('setAlertId', alertId)
      this.alertId = alertId
    },

    /**
     * 引用をセット
     * @params {string} quote
     * @return {void}
     */
    setQuote(quote: string): void {
      console.log('setQuote', quote)
      this.quote = quote
    },

    /**
     * 検索条件をセット
     * @params {any} srchCondition
     * @return {void}
     */
    setSrchCondition(srchCondition: any): void {
      console.log('setSrchCondition', srchCondition)
      this.srchCondition = srchCondition
    },

    /**
     * お気に入り記事情報をセット
     * @params {any} favoriteKijiInfo
     * @return {void}
     */
    setFavoriteKijiInfo(favoriteKijiInfo: any): void {
      console.log('setFavoriteKijiInfo', favoriteKijiInfo)
      this.favoriteKijiInfo = favoriteKijiInfo
    },
  },
})
