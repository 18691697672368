





































































import Vue from 'vue'

export default Vue.extend({
  props: {
    parentHznHolder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hznHolder: this.$$getLoginInfo('S_HZNFOLDER'),
    }
  },
  watch: {
    // 親コンポーネントの値を監視する
    parentHznHolder(newHznHolder) {
      console.log('newHznHolder', newHznHolder)
      this.hznHolder = newHznHolder
    },
  },
  mounted() {
    // エラーメッセージは日本語
    this.$$setValidationLocale('ja')
  },
  methods: {
    /**
     * リスト検索する
     * @return {Promise<void>}
     */
    async search(): Promise<void> {
      // バリデーション
      const errorMessage = await this.$$validate()
      if (errorMessage !== '') {
        alert(errorMessage)
      }
    },

    /**
     * ログアウトする
     * @return {Promise<void>}
     */
    async logout(): Promise<void> {
      // ローディング画面を表示する
      this.$$showLoading()

      // ログアウトする
      await this.$$logout()

      // TOPへリダイレクト
      if (
        (this.$$getLoginInfo('L_LOGIN_CNO') === '6' ||
          this.$$getLoginInfo('L_LOGIN_CNO') === '7') &&
        this.$$getLoginInfo('RL_LOGIN_CNO') === '1'
      ) {
        location.href = '/devicelogout/'
      } else {
        location.href = '/logout/'
      }
    },
  },
})
