import { render, staticRenderFns } from "./MyFolderModal.vue?vue&type=template&id=414d5d39&"
import script from "./MyFolderModal.vue?vue&type=script&lang=ts&"
export * from "./MyFolderModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MyFolderPagination1: require('/home/ec2-user/environment/kensakukun-app/src/components/MyFolderPagination1.vue').default,MyFolderPagination2: require('/home/ec2-user/environment/kensakukun-app/src/components/MyFolderPagination2.vue').default})
